// SPDX-FileCopyrightText: 2022 Dusan Mijatovic (dv4all)
// SPDX-FileCopyrightText: 2022 dv4all
// SPDX-FileCopyrightText: 2023 Christian Meeßen (GFZ) <christian.meessen@gfz-potsdam.de>
// SPDX-FileCopyrightText: 2023 Helmholtz Centre Potsdam - GFZ German Research Centre for Geosciences
//
// SPDX-License-Identifier: Apache-2.0
// SPDX-License-Identifier: EUPL-1.2

import {RsdHost} from '~/config/rsdSettingsReducer'
import LogoAvatar from '../layout/LogoAvatar'

function GfzLogo() {
  return (
    <a href='https://gfz-potsdam.de' target='_blank'
      className="h-[4rem]" rel="noreferrer">
      <LogoAvatar
        name='GFZ Potsdam'
        src='/images/gfz_white.svg'
        sx={{
          '& img' : {
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain'
          }
        }}
      />
    </a>
  )
}

export default function OrganisationLogo({host}: { host: RsdHost }) {

  if (host?.logo_url && host?.website) {
    const {name,logo_url,website}=host
    return (
      <div className="flex flex-wrap items-center gap-[1rem]">
        <a href={website} target="_blank"
          className="h-[4rem] w-[16rem]" rel="noreferrer">
          <LogoAvatar
            name={name}
            src={logo_url}
            sx={{
              '& img' : {
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain'
              }
            }}
          />
        </a>
        <GfzLogo />
      </div>
    )
  }
  if (host?.logo_url) {
    const {name,logo_url,website}=host
    return (
      <div className="flex flex-wrap items-center gap-[1rem]">
        <span className="h-[4rem] w-[16rem]">
          <LogoAvatar
            name={name}
            src={logo_url}
          />
        </span>
        <GfzLogo />
      </div>
    )
  }
  return null
}
